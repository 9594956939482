import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import SwiperCore, { Navigation } from "swiper"

import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css" // optional

import StatText from "./stat-text"
import Counter from "./counter"
import ReactTooltip from "react-tooltip"

SwiperCore.use([Navigation])

const SliderStats = ({ slides = {}, variant = "A", additionalSlides = [] }) => {
  const [page, setPage] = useState(0)
  const [maxPage, setMaxPage] = useState(0)
  const [width, setWidth] = useState(0)

  const [slidesComputed, setSlidesComputed] = useState([])
  const [slidesMerged, setSlidesMerged] = useState([])

  const handleChange = (a, b, c) => {
    const { progress, realIndex } = a

    setPage(parseInt(realIndex))
  }

  useEffect(() => {
    let index = 0

    const slidesKeys = Object.keys(slides || {}).filter(k => k.match(/^[A|B]_/))

    const elementsToRender = parseInt(slidesKeys.length / 3)

    let initialSlider = Array(elementsToRender).fill(null)

    for (let i = 0; i < elementsToRender; i++) {
      const labelKey = variant + "_field_label_" + i
      const valueKey = variant + "_field_value_" + i
      const infoKey = variant + "_field_info_" + i
      let prefix = ""
      if (slides[valueKey]) {
        prefix = slides[valueKey].replace(/[0-9.]/g, "")
      }

      initialSlider[i] = {
        value: slides[valueKey],
        label: slides[labelKey],
        info: slides[infoKey],
        prefix,
      }
    }

    const slidesWithoutValue = initialSlider.filter(s => s.value)

    setSlidesComputed(slidesWithoutValue)
  }, [slides])

  useEffect(() => {
    setSlidesMerged([...additionalSlides, ...slidesComputed])
  }, [slidesComputed, additionalSlides])

  useEffect(() => {
    setMaxPage(
      window.innerWidth < 1024
        ? slidesMerged.length
        : Math.ceil(slidesMerged.length / 3)
    )
  }, [slidesMerged])

  const parseNumber = (value, prefix) => {
    if (String(value).match(/\./)) {
      return parseFloat(value).toFixed(2) + prefix
    }

    return parseFloat(value).toFixed(0) + prefix
  }

  return (
    <>
      <div className='relative w-full overflow-hidden'>
        <div className='mt-10 mb-10 sm:mt-0'>
          <div
            className={`${maxPage <= 1 && "hidden"} flex justify-center mt-6`}
          >
            <button
              type='button'
              className='px-8 text-2xl font-bold text-white transition bg-transparent border-0 cursor-pointer prevButton lg:hover:text-secondary'
            >
              {"<"}
            </button>
            <div className='text-2xl'>
              {page + 1}/{maxPage}
            </div>
            <button
              type='button'
              className='px-8 text-2xl font-bold text-white transition bg-transparent border-0 cursor-pointer nextButton lg:hover:text-secondary'
            >
              {">"}
            </button>
          </div>
        </div>

        <Swiper
          slidesPerGroup={1}
          slidesPerView={1}
          spaceBetween={50}
          onSlideChange={handleChange}
          navigation={{
            prevEl: ".prevButton",
            nextEl: ".nextButton",
          }}
          breakpoints={{
            1024: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
          }}
        >
          {slidesMerged.map((s, index) => (
            <SwiperSlide key={index}>
              <div className='text-center'>
                <strong className='text-4xl'>
                  <Counter
                    value={s.value}
                    formatValue={n => parseNumber(n, s.prefix)}
                  />
                </strong>
                <StatText>{s.label}</StatText>
                {s.info ? (
                  <div
                    className='flex items-center justify-center'
                    data-tip
                    data-for={"react_tool_" + index}
                    data-class='max-w-xs'
                  >
                    <Tippy content={s.info}>
                      <span>
                        <span
                          style={{
                            padding: "0.2rem",
                            width: "0.8rem",
                            height: "0.8rem",
                            fontSize: "0.7rem",
                          }}
                          className='flex items-center justify-center italic font-bold text-black bg-white rounded-full'
                        >
                          i
                        </span>
                      </span>
                    </Tippy>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}

export default SliderStats
