import React from 'react'
import logo from '../images/timenet.svg'
import linkedin from '../images/linkedin.png'

const Footer = ({ company }) => (
  <footer id='footer' className='relative z-20 bg-primary'>
    <div className='py-16 w-11/12 mx-auto flex flex-col lg:flex-row lg:justify-between items-start'>
      <div className='mb-8 lg:grow-1 lg:mb-0 relative z-30'>
        <a
          rel='noopener noreferrer'
          href='https://timenet.it/'
          target='_blank'
          title='Timenet'
        >
          <img src={logo} className='w-auto' alt='logo' style={{height:"70px"}}/>
        </a>
      </div>
      <div className='lg:w-3/5 text-lg text-white'>
        <div className='lg:w-10/12 mx-auto lg:flex'>
          <div className='lg:w-1/2 lg:mr-4 flex flex-col'>
            <strong className='mb-4'>{company.name}</strong>
            <address className='mb-4'>
              {company.street}
              <br />
              {company.zip} - {company.city} ({company.province})
            </address>
            <span className='mb-4'>
              T.{' '}
              <a
                className='font-bold text-white'
                title='telefono'
                href={'tel:' + company.phone.replace(/[^0-9]/, '')}
              >
                {company.phone}
              </a>
            </span>
            <span className='mb-4'>
              F. <strong>{company.fax}</strong>
            </span>
            <nav className='mb-8'>
              <a
                alt='linkedin'
                href={company.socials.linkedin}
                className='no-underline'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={linkedin} className='w-6 block' alt='Linkedin' />
              </a>
            </nav>
          </div>
          <div className='lg:w-1/2 flex flex-col'>
            <span className='mb-4'>
              P.I. <strong>{company.pi}</strong> - REA{' '}
              <strong>{company.rea}</strong>
            </span>
            <span className='mb-4'>
              Registro Imprese FI <strong>{company.rifi}</strong>
            </span>
            <span className='mb-4'>
              Capitale Sociale € <strong>{company.cs}</strong>
            </span>
            <span className='mb-4'>
              Iscrizione Roc nr. <strong>{company.roc}</strong>
            </span>
            <nav className='flex flex-col lg:flex-row font-bold  mt-8 lg:mt-0'>
              <a
                href='https://timenet.it/timenet-condizioni-generali-e-privacy'
                title='privacy e cookie policy'
                rel='noopener noreferrer'
                target='_blank'
                className='text-white'
              >
                Privay Policy e Cookie Policy
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
