import React, { useEffect } from "react"

let timeout = null
let lastPosition = -1
const sections = []

const Navigator = props => {
  useEffect(() => {
    let headerHeight = 0
    const header = document.querySelector("#header")
    if (header) headerHeight = header.clientHeight

    const scrollLoop = () => {
      if (timeout) window.cancelAnimationFrame(timeout)

      // stop check position if mobile or last position is the same of actual position
      if (lastPosition === window.pageYOffset) {
        timeout = window.requestAnimationFrame(scrollLoop)
        return false
      } else lastPosition = window.pageYOffset

      checkScrollPosition()
      timeout = window.requestAnimationFrame(scrollLoop)
    }
    const checkScrollPosition = () => {
      props.children.forEach((child, index) => {
        const id = child.props.id
        const element = sections[index]
        const rect = element.getBoundingClientRect()
        const documentTop = document.body.getBoundingClientRect().top
        const start = rect.top - documentTop
        const end = rect.bottom - documentTop

        if (lastPosition >= start - headerHeight && lastPosition < end) {
          // remove current selection from headers and set new one
          const headerMenuItems = document.querySelectorAll("#header nav a")
          headerMenuItems.forEach(item => {
            item.classList.remove("text-secondary")
          })
          const currentHeaderItem = document.querySelector(
            '#header a[href="#' + id + '"]'
          )
          if (currentHeaderItem) {
            currentHeaderItem.classList.add("text-secondary")
          }
        }
      })
    }
    scrollLoop()
  }, [])
  return (
    <div>
      {props.children && Array.isArray(props.children) ? (
        props.children.map((item, index) => (
          <div key={item.props.id || index} ref={e => sections.push(e)}>
            {item}
          </div>
        ))
      ) : (
        <div ref={e => sections.push(e)}>{props.children}</div>
      )}
    </div>
  )
}
export default Navigator
