/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import SEO from './seo'
import { globalHistory } from '@reach/router'
import Header from './header'
import Footer from './footer'
import '../style/index.css'

let layoutClasses = ''

const Main = props => {
  useEffect(() => {
    const header = document.getElementById('header')
    const footer = document.getElementById('footer')
    const pathname = globalHistory.location.pathname.split('/')[1]

    if (header && footer) {
      if (pathname === '404') {
        header.classList.add('hidden')
        footer.classList.add('hidden')
      } else if (pathname === 'assurance' || pathname === 'provisioning') {
        footer.classList.add('hidden')
        header.classList.add('hidden')
      } else {
        header.classList.remove('hidden')
        footer.classList.remove('hidden')
      }
    }

    if (pathname === 'assurance' || pathname === 'provisioning' || pathname === 'CollaboratorsDetail') {
      document.getElementsByTagName('html')[0].classList.add(
        'page-html-' + pathname
      )
      document.body.classList.add(
        'page-' + pathname
      )
      layoutClasses = 'flex'
    }

    return () => {
      document.body.classList.remove(
        'page-' + pathname
      )
      document.getElementsByTagName('html')[0].classList.add(
        'page-html-' + pathname
      )
    }
  }, [])

  return <div className={layoutClasses}>{props.children}</div>
}

const Layout = ({ location, children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              company {
                name
                street
                phone
                fax
                zip
                city
                province
                pi
                rea
                rifi
                cs
                roc
                socials {
                  linkedin
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className='font-display'>
          <SEO
            description={data.site.siteMetadata.description}
            title={data.site.siteMetadata.title}
            lang='it'
          />
          <main id='main'>
            <Header siteTitle={data.site.siteMetadata.title} />
            <Main location={location}>{children}</Main>
            <Footer company={data.site.siteMetadata.company} />
          </main>
          <div className='loader z-80 inset-0 w-full fixed flex flex-col justify-center items-center h-full'>
            <div className='spinner'>
              <div className='cube1 bg-white' />
              <div className='cube2 bg-white' />
            </div>
            <p className='mt-8 text-lg text-white text-lg font-display font-bold'>
              Fetching survey results...
            </p>
          </div>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
