import React, { useEffect, useReducer, useRef } from "react"
import axios from "axios"

import { Legend } from "../components/legend"
import SideContent from "../components/side-content"
import StatText from "../components/stat-text"
import Content from "../components/content"
import Section from "../components/section"
import Counter from "../components/counter"
import Quotes from "../components/quotes"
import Conversation from "../components/conversation"

import Layout from "../components/layout"
import Image from "../components/image"
import Navigator from "../components/navigator"
import SchemaImage from "../components/schema-image"
import Modal from "../components/Modal"

import { FadeTop } from "../components/Transitions"

import blockchainImage from "../images/blockchain.png"
import blockchainImageWhite from "../images/blockchain-white.png"
import quoteImage from "../images/quotes.png"

import i18n from "../i18n.json"

import { Pie, Chart } from "react-chartjs-2"
import { BASE_URL, BLOCKCHAIN_URL, IPFS_SHOW_URL } from "../constants"
import SliderStats from "../components/sliderstats"

const getFakeStats = async (trend = "assurance") => {
  return axios(BASE_URL + `/stats?trend=${trend === "assurance" ? "A" : "B"}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
}

const getStats = async (trend = "assurance") => {
  return axios(BASE_URL + `/response/trends/${trend}`, {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
}

const getBlockchainData = (review, dispatch) => {
  dispatch({ type: "setModalData", value: {} })
  dispatch({ type: "setModalVisible", value: true })
  if (!review.hash) {
    dispatch({
      type: "setModalData",
      value: {
        success: false,
      },
    })
    return
  }
  return axios(`${BLOCKCHAIN_URL}/${review.hash}`, {
    method: "GET",
  })
    .then(response => {
      let data = response.data
      if (typeof response.data === "string") data = JSON.parse(data)

      dispatch({
        type: "setModalData",
        value: {
          success: true,
          review,
          blockchain: data,
        },
      })
    })
    .catch(() => {
      dispatch({
        type: "setModalData",
        value: {
          success: false,
        },
      })
    })
}

Chart.defaults.global.legend.display = false

const backgroundColors = ["#05C170", "#7DDF64", "#f9be00", "#FF7E6B", "#E3170A"]

const getColorClass = index => {
  const colors = [
    "text-greeny",
    "text-sky",
    "text-orangy",
    "text-secondary",
    "text-blues",
  ]
  return colors[index]
}

const initialState = {
  width: 0,
  isRequestFailed: false,
  stats: [],
  quotes: [],
  answers: [],
  indexQuote: 0,
  totalAnswered: 0,
  totalFeedback: 0,
  answer1Feedback: 0,
  answer2Feedback: 0,
  satisfaciton: [],
  filterMode: "assurance",
  slidestats: [],

  modalVisible: false,
  modalData: {},
}

function reducer (state, action) {
  switch (action.type) {
    case "setWidth":
      state.width = action.value
      return { ...state }
    case "setStats":
      state.stats = action.value
      return { ...state }
    case "setModalVisible":
      state.modalVisible = action.value
      return { ...state }
    case "setModalData":
      state.modalData = action.value
      return { ...state }
    case "setFilterMode":
      state.filterMode = action.value
      return { ...state }
    case "slidestats":
      state.slidestats = action.value
      return { ...state }
    case "setAnswer1Feedback":
      state.answer1Feedback = action.value
      return { ...state }
    case "setSatisfaction":
      state.satisfaction = action.value
      return { ...state }
    case "setRequestError":
      state.isRequestFailed = true
      return { ...state }
    case "setAnswer2Feedback":
      state.answer2Feedback = action.value
      return { ...state }
    case "setTotalAnswered":
      state.totalAnswered = action.value
      return { ...state }
    case "setTotalFeedback":
      state.totalFeedback = action.value
      return { ...state }
    case "setQuotes":
      state.quotes = action.value
      return { ...state }
    case "decrementQuote":
      state.indexQuote -= 1
      if (state.indexQuote < 0) state.indexQuote = state.quotes.length - 1
      return { ...state }
    case "incrementQuote":
      state.indexQuote += 1
      if (state.indexQuote > state.quotes.length - 1) state.indexQuote = 0
      return { ...state }
    case "setQuoteIndex":
      state.indexQuote = action.value
      return { ...state }
    default:
      return { ...state }
  }
}

const getWidth = () => {
  const pie = document.querySelector(".pie-wrapper")
  if (!pie) return 300
  const w = pie.clientWidth / 1.5
  return w
}

const IndexPage = () => {
  const wrapper = useRef(null)
  const [state, dispatch] = useReducer(reducer, initialState)

  const Switch = ({ dark }) => (
    <div
      className={`z-50 filter-switch ${
        state.filterMode === "assurance" ? "" : "selected"
      } ${dark ? "dark" : ""}`}
    >
      <div
        onClick={() => dispatch({ type: "setFilterMode", value: "assurance" })}
      >
        Feedback <div>assistenza tecnica</div>
      </div>
      <div
        onClick={() =>
          dispatch({ type: "setFilterMode", value: "provisioning" })
        }
      >
        Feedback <div>attivazione servizi</div>
      </div>
    </div>
  )

  const parseFakeStats = async () => {
    try {
      document.body.classList.add("loader")
      const res = await getFakeStats(state.filterMode)
      document.body.classList.remove("loader")

      if (res.data) {
        dispatch({ type: "slidestats", value: res.data })
      }
    } catch (error) {
      document.body.classList.remove("loader")
      dispatch({ type: "setRequestError" })
    }
  }

  const parseStats = async () => {
    try {
      document.body.classList.add("loader")
      const res = await getStats(state.filterMode)
      document.body.classList.remove("loader")
      if (res.data && res.data.stats) {
        res.data.stats = res.data.stats.map(item => {
          return {
            title: i18n.graphsTitles[item.name],
            series: item.values
              .sort((a, b) => b - a)
              .map((val, index) => ({
                label: i18n.graphsLabels[item.name][index],
                value: val,
                className: getColorClass(index),
              })),
            data: {
              labels: item.values.map(
                (el, i) => i18n.graphsLabels[item.name][i]
              ),
              datasets: [
                {
                  data: item.values.sort((a, b) => b - a),
                  backgroundColor: backgroundColors,
                  options: {
                    legend: { display: false },
                  },
                },
              ],
            },
          }
        })
        dispatch({ type: "setStats", value: res.data.stats })
        dispatch({ type: "setTotalAnswered", value: res.data.totalAnswers })
        // dispatch({ type: "setSatisfaction", value: res.data.satisfaction })
        dispatch({ type: "setQuotes", value: res.data.answers })
        dispatch({
          type: "setAnswer1Feedback",
          value: res.data.answer1Feedback,
        })
        dispatch({
          type: "setAnswer2Feedback",
          value: res.data.answer2Feedback,
        })
        dispatch({
          type: "setTotalFeedback",
          value: res.data.totalFeedback,
        })
        const newWidth = getWidth()
        dispatch({ type: "setWidth", value: newWidth })

        const selectedQuote = window.location.href.match(
          /^.+\/#(?:\/?)id_(\d+)$/
        )
        if (selectedQuote && selectedQuote.length) {
          let quoteIndex = false
          let quoteObject = false
          res.data.answers.forEach((answer, index) => {
            if (answer.response_id === selectedQuote[1]) {
              quoteIndex = index
              quoteObject = answer
            }
          })
          if (quoteIndex !== false && quoteObject) {
            dispatch({ type: "setQuoteIndex", value: quoteIndex })
            getBlockchainData(quoteObject, dispatch)
          }
        }
      }
      // console.log(state)
    } catch (e) {
      document.body.classList.remove("loader")
      dispatch({ type: "setRequestError" })
    }
  }

  const incrementQuote = () => {
    dispatch({ type: "incrementQuote" })
  }
  const decrementQuote = () => {
    dispatch({ type: "decrementQuote" })
  }

  useEffect(() => {
    // Set a load effect until apii call have finish to load
    document.body.classList.add("loader")
    // Get width for set width of graphs even in resize mode
    window.addEventListener("resize", () => {
      const newWidth = getWidth()
      dispatch({ type: "setWidth", value: newWidth })
    })

    parseStats()
    // Get benchamrks about surveys
  }, [])

  useEffect(() => {
    parseStats()
  }, [state.filterMode])

  useEffect(() => {
    parseFakeStats()
  }, [state.filterMode])

  return (
    <Layout className=''>
      {state.isRequestFailed && (
        <FadeTop>
          <div className='fixed bottom-0 right-0 flex justify-end w-full z-60 lg:w-2/5'>
            <div className='w-full px-8 py-4 m-12 text-lg font-bold text-white bg-greeny'>
              Sembra che attualmente non sia possibile recuperare i dati. Ma non
              preoccuparti, i nostri tecnici stanno già lavorando per risolvere
              il problema. Ti invitiamo quindi a riprovare più tardi.
            </div>
          </div>
        </FadeTop>
      )}
      <Navigator>
        <Section id='section-00'>
          <SideContent
            isH1
            title={i18n.sections["00"].title}
            description={i18n.sections["00"].description}
            className='pt-40'
          >
            <div>
              <a
                rel='nofollow'
                href='#section-01'
                className='inline-block px-8 py-4 font-bold no-underline uppercase transition border-solid rounded-lg xl:hover:bg-mandarin xl:hover:text-white sm:text-xl border-3 border-mandarin text-mandarin'
                title='Scopri come funziona'
              >
                Scopri come funziona
              </a>
            </div>
            <div className='flex items-center mt-16'>
              <figure className='m-0 mr-8'>
                <img
                  src={blockchainImageWhite}
                  className='flex-grow-1'
                  alt='blockchain logo'
                />
              </figure>
              <strong className='font-bold uppercase whitespace-pre-wrap'>
                {i18n.sections["00"].bcFirst}
              </strong>
            </div>
          </SideContent>
          <Content>
            <Switch />
            <div className='absolute inset-0 z-0 w-full h-full overflow-hidden'>
              <div className='w-full h-full lg:fixed lg:w-3/5'>
                <Image className='object-cover w-full h-full' />
              </div>
            </div>
            <div className='relative z-30 mt-5 text-white border-0 border-white border-solid lg:mt-0'>
              <SliderStats
                additionalSlides={[
                  {
                    label:
                      state.filterMode === "assurance"
                        ? i18n.stats.assistenza[1].title
                        : i18n.stats.attivazioni[1].title,
                    value: state.totalFeedback,
                    prefix: "",
                    info:
                      state.filterMode === "assurance"
                        ? i18n.stats.assistenza[1].tooltip
                        : i18n.stats.attivazioni[1].tooltip,
                  },
                  {
                    label:
                      state.filterMode === "assurance"
                        ? i18n.stats.assistenza[2].title
                        : i18n.stats.attivazioni[2].title,
                    value: state.answer1Feedback,
                    prefix: "%",
                    info:
                      state.filterMode === "assurance"
                        ? i18n.stats.assistenza[2].tooltip
                        : i18n.stats.attivazioni[2].tooltip,
                  },
                ]}
                slides={state.slidestats}
                variant={state.filterMode === "assurance" ? "A" : "B"}
              />
              <div>
                {(state.quotes && state.quotes.length && (
                  <div>
                    {/* <img src={quoteImage} alt='quote logo' /> */}
                    <Conversation
                      question={i18n.question}
                      answers={state.answers}
                      quotes={state.quotes}
                      selected={state.indexQuote}
                      onPrevQuote={decrementQuote}
                      onNextQuote={incrementQuote}
                    />
                    {/* <StatText>{i18n.question}</StatText>
                      <Quotes
                        quotes={state.quotes}
                        selected={state.indexQuote}
                        onPrevQuote={decrementQuote}
                        onNextQuote={incrementQuote}
                      /> */}
                    <div className='text-center'>
                      <a
                        rel='nofollow noopener noreferrer'
                        target='_blank'
                        href='#'
                        onClick={e => {
                          e.preventDefault()
                          getBlockchainData(
                            state.quotes[state.indexQuote],
                            dispatch
                          )
                        }}
                        className='inline-block px-8 py-4 font-bold no-underline uppercase transition border-solid rounded-lg xl:hover:bg-mandarin xl:hover:text-white sm:text-l border-3 border-mandarin text-mandarin'
                        title='Verifica recensione'
                      >
                        Verifica recensione
                      </a>
                    </div>
                  </div>
                )) ||
                  ""}
              </div>
            </div>
          </Content>
        </Section>
        <Section id='section-01'>
          <SideContent
            number={1}
            title={i18n.sections["01"].title}
            description={i18n.sections["01"].description}
          >
            <strong className='text-7xl'>
              {!state.isRequestFailed ? (
                <Counter value={state.totalFeedback} />
              ) : (
                "?"
              )}
            </strong>
            <br />
            <StatText>{i18n.sections["01"].surveyCompiled}</StatText>
          </SideContent>
          <Content>
            <Switch dark />
            <div ref={wrapper}>
              {!state.isRequestFailed ? (
                state.stats.map((graph, index) => (
                  <FadeTop key={"graph" + index}>
                    <div className='mb-24'>
                      <h3 className='pb-4 mb-10 text-xl font-bold text-right uppercase border-0 border-b-2 border-solid text-secondary border-secondary'>
                        {graph.title}
                      </h3>
                      <div className='flex flex-col items-center md:flex-row'>
                        <div className='w-full md:w-2/5'>
                          <Legend series={graph.series} />
                        </div>
                        <div className='flex items-center justify-center w-full pie-wrapper md:w-3/5'>
                          <div>
                            {state.width > 0 ? (
                              <Pie
                                width={state.width}
                                height={state.width}
                                data={graph.data}
                                options={{
                                  maintainAspectRatio: true,
                                  responsive: true,
                                  tooltips: { show: false },
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </FadeTop>
                ))
              ) : (
                <FadeTop>
                  <div className='flex flex-col items-center justify-center text-center text-gray-400'>
                    <p className='flex flex-col items-center justify-center text-2xl font-bold'>
                      <span className='flex items-center justify-center block w-64 h-64 mb-8 text-white bg-gray-300 rounded-full text-7xl'>
                        0%
                      </span>
                      <span>
                        Non è stato possibile generare i grafici.
                        <br />
                        Riprova più tardi.
                      </span>
                    </p>
                  </div>
                </FadeTop>
              )}
            </div>
          </Content>
        </Section>

        <Section id='section-02'>
          <SideContent
            number={2}
            title={i18n.sections["02"].title}
            description={i18n.sections["02"].description}
          />
          <Content>
            <SchemaImage />
          </Content>
        </Section>
        <Section id='section-03'>
          <SideContent
            number={3}
            title={i18n.sections["03"].title}
            description={i18n.sections["03"].description}
          />
          <Content className='text-primary'>
            <div>
              <strong className='text-6xl'>
                {state.isRequestFailed ? (
                  "?"
                ) : (
                  <Counter
                    formatValue={n => parseFloat(n).toFixed(1) + "%"}
                    value={state.answer1Feedback}
                  />
                )}
              </strong>
              <StatText>{i18n.sections["03"].clientHappy}</StatText>
            </div>
            <div>
              <strong className='text-6xl'>
                {state.isRequestFailed ? (
                  "?"
                ) : (
                  <Counter
                    formatValue={n => parseFloat(n).toFixed(1) + "%"}
                    value={state.answer2Feedback}
                  />
                )}
              </strong>
              <StatText>{i18n.sections["03"].easyHelp}</StatText>
            </div>
            <div>
              <img src={blockchainImage} alt='blockchain logo' />
              <StatText>{i18n.sections["03"].firstBC}</StatText>
            </div>
          </Content>
        </Section>
        <Modal
          visibility={state.modalVisible}
          title='Come funziona'
          onClose={() => dispatch({ type: "setModalVisible", value: false })}
        >
          {state.modalData.success === true ? (
            <div>
              <div>
                <p style={{ marginTop: "-1rem" }}>
                  Tutti i commenti sono salvati su un'architettura di rete
                  basata su blockchain.
                </p>
                <p>
                  Per poter visualizzare il contenuto nel formato originale
                  direttamente sul registro è sufficiente cliccare sul bottone{" "}
                  <i>Visualizza transazione</i> e successivamente sul link{" "}
                  <i>Mostra sul Gateway IPFS</i>
                </p>
              </div>
              <div className='px-4 py-4 text-center'>
                <a
                  rel='nofollow noopener noreferrer'
                  target='_blank'
                  href={`${IPFS_SHOW_URL}/${state.modalData.review.hash}`}
                  className='inline-block px-8 py-4 no-underline uppercase transition border-solid rounded-lg xl:hover:bg-mandarin xl:hover:text-white sm:text-l border-1 border-mandarin text-mandarin'
                  title='Visualizza transazione'
                >
                  Visualizza transazione
                </a>
              </div>
              <a
                href={`${IPFS_SHOW_URL}/${state.modalData.review.hash}`}
                rel='nofollow noopener noreferrer'
                target='_blank'
                title='Visualizza transazione'
                className='no-underline'
              >
                {state.modalData.review.customer_comment ===
                state.modalData.blockchain.comment ? (
                  <div className='py-4 mt-4 mt-5 text-center text-white bg-greeny text-bold'>
                    Recensione certificata su Blockchain
                    <div className='mt-8'>
                      <img
                        src={blockchainImageWhite}
                        className='flex-grow-1'
                        alt='blockchain logo'
                      />
                    </div>
                  </div>
                ) : (
                  <div className='py-4 mt-4 mt-5 text-center bg-mandarin'>
                    Recensione modificata
                  </div>
                )}
              </a>
            </div>
          ) : state.modalData.success === false ? (
            <div className='py-4 mt-4 mt-5 text-center bg-orangy'>
              Recensione non verificata
            </div>
          ) : (
            <div className='py-4 mt-4 mt-5 text-center'>Caricamento</div>
          )}
        </Modal>
      </Navigator>
    </Layout>
  )
}

export default IndexPage
