import React from 'react'
import { Fade } from 'react-reveal'

export default function ({
  visibility,
  onClose,
  title,
  children
}) {
  return (
    <Fade when={visibility === true}>
      <div className={`modal fixed w-full h-full top-0 left-0 flex items-center justify-center ${visibility === true ? 'modal-open modal-active' : 'pointer-events-none'}`}>
        <div className='modal-overlay absolute w-full h-full bg-gray-900 opacity-50' />
        <div className='modal-container relative bg-white w-11/12 mx-auto rounded shadow-lg z-50 overflow-y-auto'>
          <div className='modal-content py-4 text-left px-8'>
            <div className='flex justify-between items-center pb-3 text-black'>
              <p className='text-2xl font-bold text-black'>{title}</p>
              <div onClick={onClose} className='modal-close absolute sm:static cursor-pointer z-50'>
                <svg className='fill-current text-black cursor-pointer' xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22'>
                  <path d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z' />
                </svg>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </Fade>
  )
}
