import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { globalHistory } from '@reach/router'
import i18n from '../i18n.json'
import logo from '../images/timenet.svg'

let isCollaboratorsDetail = false

const Header = () => {
  const [urlSelected, setUrlSelected] = useState(null)

  useEffect(() => {
    const pathname = globalHistory.location.pathname.split('/')[1]

    if (pathname === 'CollaboratorsDetail') {
      isCollaboratorsDetail = true
    }
  }, [])

  if (isCollaboratorsDetail) {
    return (
      <header
        id='header'
        className='h-24 lg:h-32 z-0 fixed left-0 top-0 w-full flex items-center lg:items-end transition text-white'
      >
        <div
          className='flex justify-center md:justify-between items-center mx-auto pb-4 md:pb-8 w-11/12 border-0 '
        >
          <a
            rel='noopener noreferrer'
            href='https://timenet.it/'
            target='_blank'
            title='Timenet'
          >
            <img src={logo} className='relative z-30' alt='logo' style={{height:"70px"}}/>
          </a>
        </div>
      </header>
    )
  } else {
    return (
      <header
        id='header'
        className='h-24 lg:h-32 z-50 fixed left-0 top-0 w-full flex items-center lg:items-end transition text-white'
      >
        <div
          className='flex justify-center md:justify-between items-center mx-auto pb-4 md:pb-8 w-11/12 md:border-solid border-0 md:border-b-2 md:border-white '
        >
          <a
            rel='noopener noreferrer'
            href='https://timenet.it/'
            target='_blank'
            title='Timenet'
          >
            <img src={logo} className='relative z-30' alt='logo' style={{height:"70px"}}/>
          </a>
          <nav className='hidden md:block'>
            {i18n.menu.map((item, index) => (
              <a
                rel='nofollow'
                key={'menu' + index}
                title={item.label}
                className={`text-white ${
                    urlSelected === item.hash ? 'text-secondary' : ''
                } xl:hover:text-secondary transition font-bold text-xl ml-8 no-underline`}
                href={item.hash}
                onClick={() => setUrlSelected(item.hash)}
              >
                {item.label}
              </a>
            ))}
          </nav>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ''
}

export default Header
