import React, { useEffect } from 'react'
import i18n from '../i18n.json'

import blueQuoteImage from '../images/blue-quotes.png'
import orangeQuoteImage from '../images/orange-quotes.png'
import { FadeLeft, FadeRight } from '../components/Transitions'

const parseDate = date => {
  const newDate = new Date(date)
  const day = newDate.getDate()
  const month = newDate.getMonth() + 1
  const year = newDate.getFullYear()
  return `${day < 10 ? '0' + day : day}/${
    month < 10 ? '0' + month : month
    }/${year}`
}

const getRandomIndex = () => {
  const max = i18n && i18n.timenetAnswers && i18n.timenetAnswers.length ? (i18n.timenetAnswers.length - 1) : 0
  return getRandomInt(0, max)
}

const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

let previousAnswerIndex = ''
let responseIndex = getRandomIndex()

const Conversation = props => {
  const activeFadeAnimation = () => {
    document.getElementById('quote').className = 'BaloonContainer FadeLeft-enter FadeLeft-appear'
    document.getElementById('answer').className = 'BaloonContainer FadeRight-enter FadeRight-appear'
    setTimeout(() => {
      document.getElementById('quote').className = 'BaloonContainer FadeLeft-enter FadeLeft-enter-active FadeLeft-appear FadeLeft-appear-active'
      document.getElementById('answer').className = 'BaloonContainer FadeRight-enter FadeRight-enter-active FadeRight-appear FadeRight-appear-active'
    }, 100)
    setTimeout(() => {
      document.getElementById('quote').className = 'BaloonContainer FadeLeft-enter-done FadeLeft-appear-done'
      document.getElementById('answer').className = 'BaloonContainer FadeRight-enter-done FadeRight-appear-done'
    }, 20000)
  }

  const returnAnswer = (answerIndex, force) => {
    if (props.quotes[props.selected] && props.quotes[props.selected].comment) {
      return props.quotes[props.selected].comment
    } else {
      const randomIndex = answerIndex
      if (randomIndex !== previousAnswerIndex || force) {
        previousAnswerIndex = answerIndex
        return i18n.timenetAnswers[answerIndex]
      } else {
        return returnAnswer(getRandomIndex())
      }
    }
  }

  const answerText = returnAnswer(responseIndex, true)

  return (
    <div className='ConversationContainer'>
      {props.quotes && props.quotes.length > 0 && (
        <div className='flex justify-center mb-4 mt-4'>
          <button
            type='button'
            className='cursor-pointer text-2xl px-8 font-bold bg-transparent border-0 text-white transition lg:hover:text-secondary'
            onClick={() => { responseIndex = getRandomIndex(); props.onPrevQuote(); activeFadeAnimation() }}
          >
            {'<'}
          </button>
          <div className='text-2xl'>
            {props.selected + 1}/{props.quotes.length}
          </div>
          <button
            type='button'
            className='cursor-pointer text-2xl px-8 font-bold bg-transparent border-0 text-white transition lg:hover:text-secondary'
            onClick={() => { responseIndex = getRandomIndex(); props.onNextQuote(); activeFadeAnimation() }}
          >
            {'>'}
          </button>
        </div>
      )}

      <Baloon blue text={props.question} />
      <Baloon {...props.quotes[props.selected]} id='quote' classes='' text={props.quotes[props.selected].customer_comment || ''} />
      <Baloon blue id='answer' text={answerText} />
      {/* {(props.quotes || []).map((quote, index) =>
        <Baloon key={index} {...quote} text={quote.customer_comment} />
      )} */}
    </div>
  )
}

const Baloon = props => {
  return (
    props.blue
      ? <FadeRight>
        <div id={props.id} className={`BaloonContainer ${props.classes || ''}`}>
          <div className='QuotesContainer'><img src={blueQuoteImage} alt='blue quote' className='blue quotes-image' /></div>
          <div className={`Baloon ${props.blue ? 'blue' : 'orange'}`}>
            <blockquote>{props.text || ''}</blockquote>
          </div>
        </div>
      </FadeRight>
      : <FadeLeft>
        <div id={props.id} className={`BaloonContainer ${props.classes || ''}`}>
          <div className={`Baloon ${props.blue ? 'blue' : 'orange'}`}>
            <blockquote>{props.text || ''}</blockquote>
            <div className='info'>
              <span>{(props.customer).trim()},{' '}</span>
              <span>{' '}{parseDate(props.compile_at)}</span>
            </div>
          </div>
          <div className='QuotesContainer'><img src={orangeQuoteImage} alt='orange quote' className='orange quotes-image' /></div>
        </div>
      </FadeLeft>
  )
}

export default Conversation
