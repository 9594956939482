import React, { useEffect, useState, useRef } from 'react'
import AnimatedNumber from 'react-animated-number'

const Counter = props => {
  const [value, setValue] = useState(0)
  const element = useRef(null)
  useEffect(() => {
    const isElementVisible = () => {
      if (element.current) {
        const rect = element.current.getBoundingClientRect()
        const height = window.innerHeight
        if (rect.top < height) return true
      }
      return false
    }
    window.addEventListener('scroll', () => {
      if (isElementVisible() && props.value !== value) setValue(props.value)
    })
    if (isElementVisible() && props.value !== value)
      setTimeout(() => setValue(props.value), 100)
  }, [props.value])
  return (
    <span ref={element}>
      <AnimatedNumber
        value={parseFloat(value)}
        formatValue={
          (props.formatValue && (n => props.formatValue(n))) ||
          (n => parseFloat(n).toFixed(0))
        }
        duration={props.duration || 3000}
      />
    </span>
  )
}
export default Counter
