import React, { useEffect, useState, useRef } from 'react'
import Counter from '../components/counter'

const classTitle = 'm-0 font-bold mb-8 text-3xl lg:text-5xl xxl:text-6xl'

const Fade = props => {
  const [isVisible, setValue] = useState(false)
  const element = useRef(null)

  useEffect(() => {
    const isElementVisible = () => {
      if (element.current) {
        const rect = element.current.getBoundingClientRect()
        const height = window.innerHeight
        if (rect.top + height / 5 < height) return true
      }
      return false
    }
    window.addEventListener('scroll', () => {
      if (isElementVisible()) setValue(true)
    })
    setValue(isElementVisible())
  }, [])

  return (
    <div ref={element} className={`${(isVisible && 'fade-in') || 'fade-out'}`}>
      {props.children}
    </div>
  )
}

const SideContent = props => (
  <div
    className={`w-full lg:w-2/5 bg-primary py-16 lg:pt-32 lg:pb-0 text-white lg:min-h-screen ${
      props.className
    }`}
  >
    <div
      style={{ top: '10rem' }}
      className='block lg:sticky lg:py-12 w-4/5 m-auto'
    >
      {props.number && (
        <span className='text-7xl md:text-huge font-bold lg:text-huge xxl:text-giant relative text-secondary'>
          <Counter
            duration={500}
            formatValue={n => '0' + parseInt(n, 10)}
            value={props.number}
          />
        </span>
      )}
      <div
        className={`relative z-10 ${
          props.number ? 'lg:-mt-20 xxl:-mt-24' : ''
        }`}
      >
        <Fade>
          {props.isH1 ? (
            <h1 className={classTitle}>{props.title}</h1>
          ) : (
            <h2 className={classTitle}>{props.title}</h2>
          )}
          <p className='text-lg'>{props.description}</p>
          {props.children && <div className='mt-16'>{props.children}</div>}
        </Fade>
      </div>
    </div>
  </div>
)
export default SideContent
